import generateNetwordRequset from '@/utils/generateNetwordRequset'
const nr = generateNetwordRequset()

export default {
  getPlatformInfo(user_id) {
    return nr.get('/mirror-data/live/account/list', { params: { user_id } })
  },
  // userList
  getUserList({
    city_code,
    days,
    series_ids,
    page_size,
    current,
    author_id,
    origin
  }) {
    return nr.get('/mirror-data/customer_es/list', {
      params: {
        city_code,
        days,
        series_ids,
        page_size,
        current,
        author_id,
        origin
      }
    })
  },

  // 购车城市下拉列表
  getBuyCarList() {
    return nr.get('/mirror-data/customer_favorite_series/order/city')
  },
  // 购车城市下拉列表(省市分开)
  getBuyCarListProps({
    parent_id,
    level
  }) {
    return nr.get('/mirror-data/customer_favorite_series/areas', {
      params: {
        parent_id,
        level
      }
    })
  },
  // 查询车系下拉列表
  getSeriesList({
    author_id,
    origin
  }) {
    return nr.get('/mirror-data/customer_es/interested/series', {
      params: {
        author_id,
        origin
      }
    })
  },
  // 直播间粉丝跟主播的互动信息
  getUsgetPollingInfoerList({
    type,
    fans_id,
    author_id
  }) {
    return nr.get(`/mirror-data/${type}/live-polling/fans/info`, {
      params: {
        fans_id,
        author_id
      }
    })
  },
  // 直播间粉丝意向
  getIntention({
    type,
    fans_id,
    author_id,
    series_ids
  }) {
    return nr.get(`/mirror-data/${type}/live-polling/fans/seriesAndArea`, {
      params: {
        fans_id,
        author_id,
        series_ids
      }
    })
  },
  // 查询价值互动
  getPollingV({
    type,
    fans_id,
    author_id,
    current,
    page_size
  }) {
    return nr.get(`/mirror-data/${type}/live-polling/fans/polling/v`, {
      params: {
        fans_id,
        author_id,
        current,
        page_size
      }
    })
  },
  // 检查待更新
  getIsHaveNew({
    origin,
    author_id
  }) {
    return nr.get(`/mirror-data/customer_es/crawl-data`, {
      params: {
        origin,
        author_id
      }
    });
  },
  // 添加潜客
  addIntention(body) {
    return nr.post(`/mirror-data/customer_favorite_series/save`, body)
  },
  // 回访统计
  getStatistics(params) {
    return nr.get('/mirror-data/customer_es/customer-revisit-list', { params })
  },
  // 获取粉丝统计信息
  getFansInfo(params) {
    return nr.get('/mirror-data/customer_es/author-fans-info', { params })
  },
  // 潜客回访
  getReturnVisit(params) {
    return nr.get('/mirror-data/customer_es/customer', { params })
  },
  // 主播最近一次回访话术
  getLastWord(params) {
    return nr.get('/mirror-data/customer_es/customer-contact-recently', { params })
  }

}