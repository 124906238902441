import axios from 'axios'
import router from '@/router'
import cookies from '@/utils/cookies'

export default function (config) {
  const defaultConfig = {
    timeout: 1000 * 30,
    needTocken: true,
    baseURL: process.env.VUE_APP_DY_BASE_URL,
    needMessage: true
  }
  const _config = Object.assign({}, defaultConfig, config)
  const _axios = axios.create({
    baseURL: _config.baseURL,
    timeout: _config.timeout
  })

  _axios.interceptors.request.use(
    requestConfig => {

      if (_config.needTocken) {
        requestConfig.headers['token'] = cookies.getCookies('token')
      }
      return requestConfig
    },
    error => {
      console.error(error) // for debug
      return Promise.reject(error)
    }
  )

  // response interceptor
  _axios.interceptors.response.use(
    response => {
      if (response.data.errcode) {
        if (response.data.errcode === 5002 || response.data.errcode === 1001) {
          cookies.removeCookies('token')
          router.replace({ name: 'login' })
        } else if (response.data.errcode === 1004) {
          // 没有权限
          // window.location.href = '/welcome'
        } else if (_config.needMessage) {
        }
      }
      return response
    },
    error => {
      return Promise.reject(error)
    }
  )
  return _axios
}